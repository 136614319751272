exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-templates-about-about-js": () => import("./../../../src/templates/about/about.js" /* webpackChunkName: "component---src-templates-about-about-js" */),
  "component---src-templates-contact-contact-js": () => import("./../../../src/templates/contact/contact.js" /* webpackChunkName: "component---src-templates-contact-contact-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-projects-all-projects-js": () => import("./../../../src/templates/projects/allProjects.js" /* webpackChunkName: "component---src-templates-projects-all-projects-js" */),
  "component---src-templates-projects-projects-js": () => import("./../../../src/templates/projects/projects.js" /* webpackChunkName: "component---src-templates-projects-projects-js" */),
  "component---src-templates-single-project-single-project-js": () => import("./../../../src/templates/singleProject/singleProject.js" /* webpackChunkName: "component---src-templates-single-project-single-project-js" */)
}

